import { defineStore } from "pinia";
import axios from "axios";
export const useProductCatalogStore = defineStore("product-catalog", {
    state: () => ({
        products: [],
        productsCategories: [],
        productsBrands: []
        
    }),
    getters: {
    }
    ,
    actions: {
      loadProducts(products) {
        this.products = products
      },
      loadProductsCategorias(productsCategories) {
        this.productsCategories = productsCategories
      },
      async loadProductsBrands() {
        const {data} = await axios.get(`${process.env.VUE_APP_URL}/brands/`)
        this.productsBrands = data
      }
    },
})